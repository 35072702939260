import { useEffect } from 'react';
import { setLanguage, setTranslations } from 'react-switch-lang';
import Script from 'next/script';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { useRouter } from 'next/router';
import { Rubik } from 'next/font/google';

import '../styles/Globals.scss';
import '../styles/Bootstrap.scss';
import { isProduction } from '../utils/HostingEnv';
import English from '../languages/English.json';
import French from '../languages/French.json';

config.autoAddCss = false;

setTranslations({
  en: English,
  fr: French,
});

const rubik = Rubik({
  subsets: ['latin'],
  style: ['normal', 'italic'],
});

function App({ Component, pageProps }) {
  const router = useRouter();
  setLanguage(router.locale);
  const helpCenterLink = `https://help.paymentsource.net/widget/paysimply/${router.locale}`;

  useEffect(() => {
    function handleHistoryChange() {
      window.isPopState = false;

      // list of history state keys, where the index represents the relative position of the history
      // used with window.lastHistoryKey to determine the direction of the history change
      window.historyKeys ??= [];
      /** true if this route change is triggered by browser back/forward button */
      const isPreviouslyVisited = window.historyKeys.indexOf(window.history.state.key) > -1;
      if (!isPreviouslyVisited) { // if this is a new history, add it to the list after the last one
        const lastPosition = window.historyKeys.indexOf(window.lastHistoryKey);
        window.historyKeys[lastPosition + 1] = window.history.state.key;
      }
      window.lastHistoryKey = window.history.state.key; // always update lastHistoryKey
    }
    handleHistoryChange(); // manually called on mount to store initial history
    router.events.on('routeChangeComplete', handleHistoryChange);
    router.events.on('hashChangeComplete', handleHistoryChange);
    return () => {
      router.events.off('routeChangeComplete', handleHistoryChange);
      router.events.off('hashChangeComplete', handleHistoryChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <style jsx global>
        {`
          html, body {
            font-family: ${rubik.style.fontFamily} !important;
          }
        `}
      </style>
      {getLayout(<Component {...pageProps} />)}
      {Component.noHelpCentre || <script defer src="https://help.paymentsource.net/WidgetJS.js" link={helpCenterLink} buttoncolor="#bb1b20" />}
      {isProduction && (
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2Z4DFFFY84"
          strategy="afterInteractive"
        />
      )}
    </>
  );
}

export default App;
